import React from "react";
import { PageLayout } from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";

export default class WhereSJunebugPage extends React.PureComponent {
  render() {
    return (
      <WebsiteLayout>
        <PageLayout hero={null}>
          <Grid
            stackable={true}
            className={"calendar-page"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={10}>
              <h1>Where's Junebug?</h1>
              <iframe
                width={"100%"}
                className={"calendar-iframe"}
                height={"600"}
                src={
                  "https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23EF6C00&ctz=America%2FChicago&showTabs=0&showPrint=1&showNav=1&showCalendars=1&title=Junebug%20Carolina%20Flare%20Events&src=MmUwYWJiZmM2YWYxZmFlZjhjNGE4MzJkYTBmNTFiZjlkNGMwMDc4OWEwNzQ3ZjA1YTdjOGJjMTRmYWM2NDVjZkBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&color=%238E24AA"
                }
                scrolling={"no"}
                frameborder={"0"}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}
